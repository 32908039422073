@import '@styles/main.scss';

.modal {
    &.dealer-return-modal {
        .brand-ford & {
            .modal-content {
                .modal-body {
                    margin-top: unset;
                    flex-direction: column;
                    width: 100%;
                    text-align: left;
                    padding: pToR(24) pToR(40);
                    margin-bottom: unset;

                    .dealer-return-only-modal-header {
                        width: 100%;
                        font-family: $ford-f1-font-medium;
                        font-size: pToR(24);
                        font-weight: 600;
                        font-style: normal;
                        line-height: pToR(32);
                        letter-spacing: normal;
                        color: $fds-preimagine-dark-text;
                        text-align: left;
                        margin-bottom: pToR(23);
                    }

                    p {
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(16);
                        font-style: normal;
                        font-weight: 400;
                        line-height: pToR(20);
                        letter-spacing: normal;

                        .dealer-name {
                            font-family: $ford-f1-font-bold;
                        }
                    }
                }

                .modal-footer {
                    padding: pToR(16) pToR(40);
                    @include desktop {
                        margin-bottom: unset;
                    }

                    .primary-button {
                        margin-right: 0;
                    }

                    .secondary-button {
                        margin-left: pToR(30);

                        .fmc-text-button {
                            margin-left: unset;
                        }
                    }
                }
            }
        }
    }
}
