@import '@styles/main.v5';
@import '@styles/common';

.secondary-nav__container {
    position: sticky;
    height: pToR(56);
    top: 0;
    width: 100%;
    z-index: 2001;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);

    &.is-expanded {
        background-color: white;
    }

    @include mobile {
        background-color: white;
    }
}

.secondary-nav__clickable-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: pToR(1440);

    @include mobile {
        max-width: 100%;
    }

    &:focus {
        outline: unset;
    }

    &:focus-visible {
        outline: 2px solid $fds-reimagine-secondary-blue;
        outline-offset: pToR(3);
    }
}
.secondary-nav__content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    margin: auto;

    .header-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin: auto;
        padding: 0 pToR(56);

        @include mobile {
            max-width: 100%;
            padding: 0 pToR(16);
        }
    }

    .my-shortcuts-label {
        color: black;
        font-family: $ford-f1-font-medium;
        font-size: pToR(16);
        line-height: pToR(24);
        letter-spacing: normal;

        @include mobile {
            color: $fds-reimagine-secondary-blue;
        }
    }

    .view-all__toggle-container {
        color: $fds-reimagine-secondary-blue;
        font-family: $ford-f1-font-regular;
        font-size: pToR(14);
        line-height: pToR(20);
        letter-spacing: normal;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: pToR(12);

        .secondary-nav__right-caret {
            width: pToR(16);
            align-self: flex-end;

            svg {
                transition: transform 0.3s linear;
                transform: rotate(90deg) translateX(2px);
            }
        }

        &.is-expanded {
            .secondary-nav__right-caret svg {
                transform: rotate(270deg) translateX(-2px);
            }
        }
    }
    .view-all__label {
        @include mobile {
            display: none;
        }
    }

    .expanded {
        &-container {
            position: absolute;
            top: pToR(56);
            width: 100%;
            background-color: white;
            display: grid;
            grid-template-rows: 0fr;
            transition: all 300ms ease-out;
            overflow: hidden;

            @include mobile {
                max-height: 0;
                left: 0;
            }

            @include tablet {
                padding: 0 pToR(50);
                max-width: pToR(1440);
            }

            &.is-expanded {
                grid-template-rows: 1fr;
                transition: all 300ms ease-in;

                @include mobile {
                    max-height: calc(100vh - 56px);
                    overflow-y: auto;
                }

                .secondary-button {
                    @include media('>=480px') {
                        width: fit-content;
                    }
                    @include tablet {
                        width: unset;
                    }
                }

                @include tablet {
                    padding: 0 pToR(50) pToR(54);
                }

                .expanded-content {
                    @include mobile {
                        height: 100vh;
                        flex-direction: column;
                        overflow-y: auto;
                    }
                }
            }

            @include mobile {
                background-color: white;
                padding: 0 pToR(16);
            }
        }

        &-content {
            display: grid;
            grid-template-columns: 1fr;
            grid-auto-rows: min-content;
            width: 100%;
            overflow: hidden;

            @include tablet {
                grid-template-columns: repeat(3, minmax(214px, 320px));
                transform: translateY(14px);
                padding: 0 pToR(6);
                column-gap: pToR(16);
                row-gap: pToR(32);
            }

            &__group {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @include mobile {
                    width: 100%;
                    gap: pToR(8);
                    margin-bottom: pToR(16);
                }

                &--item {
                    a {
                        color: black;
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(16);
                        line-height: pToR(24);
                        letter-spacing: normal;

                        &:hover {
                            color: $fds-reimagine-link-hover-blue;
                            text-decoration: underline 1px solid;
                            text-underline-offset: pToR(4);
                            text-underline-color: $fds-reimagine-link-hover-blue;
                        }
                        &:focus-visible {
                            outline: 2px solid $fds-reimagine-link-hover-blue;
                            outline-offset: pToR(3);
                        }
                    }
                    @include tablet {
                        margin-bottom: pToR(9);
                    }
                }
                &--heading {
                    font-size: pToR(14);
                    line-height: pToR(20);
                    color: $fds-reimagine-dark-gray;
                    font-family: $ford-f1-font-regular;
                    letter-spacing: normal;

                    @include mobile {
                        margin-bottom: pToR(3);
                    }
                    @include tablet {
                        margin-bottom: pToR(9);
                    }
                }
                &--divider {
                    border-bottom-color: $fds-reimagine-disabled-background-color;
                    max-width: 100%;
                    box-shadow: none;

                    @include mobile {
                        margin-top: pToR(12);
                        margin-bottom: pToR(8);
                    }
                }
            }
        }
    }
}
